import React, { FormEvent, HTMLProps, useState } from "react";

import "@fontsource/montserrat";

import { Footer, Navigation, Title } from "./index";

const Input = ({ className, ...rest }: HTMLProps<HTMLInputElement>) => {
  const classList: string = className
    ? `px-2 py-1 rounded border border border-cbd-truth-blue bg-gray-100 outline-none focus:ring-4 focus:ring-cbd-truth-dark-blue ${className}`
    : "px-2 py-1 rounded border border border-cbd-truth-blue bg-gray-100 outline-none focus:ring-4 focus:ring-cbd-truth-dark-blue";

  return (
    <input className={classList} {...rest} />
  );
};

const TextArea = ({ className, ...rest }: HTMLProps<HTMLTextAreaElement>) => {
  const classList: string = className
    ? `px-2 py-1 rounded border border border-cbd-truth-blue bg-gray-100 outline-none focus:ring-4 focus:ring-cbd-truth-dark-blue ${className}`
    : "px-2 py-1 rounded border border border-cbd-truth-blue bg-gray-100 outline-none focus:ring-4 focus:ring-cbd-truth-dark-blue";

  return (
    <textarea className={classList} {...rest} />
  );
};

const Form = (): JSX.Element => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    setSubmitted(true);
  };

  return (
    <>
      {!submitted
        ?
          <form className="flex flex-wrap mt-5" onSubmit={handleSubmit}>
            <div className="flex space-x-3 w-full">
              <Input className="w-1/3" type="text" placeholder="Name" />
              <Input className="w-2/3" type="email" placeholder="E-mail address" />
            </div>
            <TextArea className="w-full mt-5" placeholder="Message" rows={7} />
            <div className="flex w-full justify-center">
              <button className="mt-5 bg-cbd-truth-blue text-white text-2xl font-semibold px-16 py-2 rounded outline-none focus:ring-4 focus:ring-cbd-truth-dark-blue" type="submit">SEND</button>
            </div>
          </form>
        :
          <p className="mt-5">Thank you for your message. We will get back to you as soon as possible.</p>
      }
    </>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="flex text-cbd-truth-dark-blue text-lg font-light flex-wrap justify-center flex-grow p-10">
      <p className="w-full text-center">If you have questions about CBD Truth, please fill out the form below.</p>
      <div className="mt-5 w-3/5 border-2 border-cbd-truth-blue rounded bg-cbd-truth-super-light-blue p-10">
        <p className="text-2xl font-medium">CONTACT US!</p>
        <Form />
        <p className="w-full text-center mt-5">CBD Truth, 800-555-1687</p>
      </div>
    </div>
  );
};

const CBDTruthPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Montserrat" }}>
      <Title />
      <Navigation />
      <Body />
      <Footer />
    </div>
  );
};

export default CBDTruthPage;
